import './style.css';
import {Map, View} from 'ol';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import {linear, inAndOut, easeOut} from 'ol/easing';
import Translate from 'ol/interaction/Translate.js';
import {useGeographic} from 'ol/proj';
import * as olCoordinate from 'ol/coordinate';
useGeographic()
const openCycleMapLayer = new TileLayer({
  source: new OSM({
    url:
      'https://beta-01.nyc3.digitaloceanspaces.com/tiles/1/{z}_{x}_{y}.png',
  }),
});
const view = new View({
  center: [0,0], zoom: 0
});


const map = new Map({
  target: 'map',
  layers: [openCycleMapLayer],
  view: view
});

const animationModes = ['up-and-down', 'fly-in-out']
const state={
  animate: true,
  mode: animationModes[1]
}

function animate(mode){
  console.log('animating')
  switch(mode){
    case 'up-and-down':
      view.animate({center: [-5.744699365355431,-49.75571909447095], zoom: 3.3219280948873626, duration: 6643, easing: linear},
          {center: [-3.0552462403554315,-21.27299487509312], zoom: 3.3219280948873626, duration: 6643, easing: linear},
          {center: [16.420217626832063,15.746790392690187], zoom: 3.3219280948873626, duration: 6643, easing: linear},
          {center: [20.513174169800806,51.72940985406234], zoom: 3.3219280948873626, duration: 6643, easing: linear},
          {center: [22.082571142457056,71.13596133491117], zoom: 3.3219280948873626, duration: 6643, easing: linear},
          {center: [24.524029985239217,79.33404004283864], zoom: 4.281928094887362, duration: 8563, easing: linear},
          {center: [23.765275204049523,71.35348102887644], zoom: 4.281928094887362, duration: 8563, easing: linear},
          {center: [19.36127834233223,59.0724096575789], zoom: 4.281928094887362, duration: 8563, easing: linear},
          {center: [18.031833790846914,34.05800259344235], zoom: 4.281928094887362, duration: 8563, easing: linear},
          {center: [12.712643685424466,7.75949079919863], zoom: 4.281928094887362, duration: 8563, easing: linear},
          {center: [1.8907165420252903,-23.09488134097313], zoom: 4.281928094887362, duration: 8563, easing: linear},
          {center: [-6.821832776481076,-47.314842697114045], zoom: 4.281928094887362, duration: 8563, easing: linear},
          {center: [-7.753121674271768,-62.52281985493454], zoom: 4.281928094887362, duration: 8563, easing: linear},
          {center: [-5.317877449121097,-71.33536284221455], zoom: 4.281928094887362, duration: 8563, easing: linear},
          {center: [-3.5530030976378253,-79.52129850066892], zoom: 4.281928094887362, duration: 8563, easing: linear},
          {center: [1.863747569443754,-75.10107753347539], zoom: 5.548594761554027, duration: 11097, easing: linear},
          {center: [3.201617122344742,-71.34232037494053], zoom: 5.508594761554027, duration: 11017, easing: linear},
          {center: [3.0808775014255096,-67.6244636851358], zoom: 5.555261428220694, duration: 11110, easing: linear},
          {center: [-4.913331311541204,-63.381325317898224], zoom: 5.641928094887361, duration: 11283, easing: linear},
          {center: [-9.540107548778286,-56.549986863942884], zoom: 5.768594761554027, duration: 11537, easing: linear},
          {center: [-0.4769788537576976,-48.093051875743576], zoom: 5.8019280948873595, duration: 11603, easing: linear},
          {center: [5.524027769413153,-37.87974298674458], zoom: 5.80770545050182, duration: 11615, easing: linear},
          {center: [4.96638784573424,-29.471713987087853], zoom: 5.867705450501822, duration: 11735, easing: linear},
          {center: [-0.06964792054122328,-15.149552900562128], zoom: 5.867705450501822, duration: 11735, easing: linear},
          {center: [-8.382357173733928,-2.120448412661162], zoom: 5.867705450501822, duration: 11735, easing: linear},
          {center: [-6.737011955790644,13.04330655036921], zoom: 5.867705450501822, duration: 11735, easing: linear},
          {center: [-4.8291546822706355,26.911484985638694], zoom: 5.841038783835153, duration: 11682, easing: linear},
          {center: [-5.9097458208629945,39.122645623255295], zoom: 5.861038783835154, duration: 11722, easing: linear},
          {center: [27.17399860437282,53.94866200389413], zoom: 5.86770545050182, duration: 11735, easing: linear},
          {center: [29.40853338063961,61.97014293653734], zoom: 5.961038783835154, duration: 11922, easing: linear},
          {center: [49.44818979760447,67.78099204975359], zoom: 5.961038783835154, duration: 11922, easing: linear},
          {center: [52.12578235529658,72.63736450195901], zoom: 6.014372117168488, duration: 12028, easing: linear},
          {center: [47.76327536439133,75.95414308392913], zoom: 6.0410387838351545, duration: 12082, easing: linear},
          {center: [39.295407494440774,78.25187112342019], zoom: 6.061038783835154, duration: 12122, easing: linear},
          {center: [37.73333850635259,80.86086755167833], zoom: 6.082878188235859, duration: 12165, easing: linear},
          {center: [23.847639533625166,83.07451832571351], zoom: 6.082878188235859, duration: 12165, easing: linear},
          {center: [20.664750885219277,83.4837001086238], zoom: 6.9841124829957195, duration: 13968, easing: linear},
          {center: [17.817549351964242,82.55369546573979], zoom: 6.9841124829957195, duration: 13968, easing: linear},
          {center: [13.262842641217462,81.71393083842628], zoom: 6.9841124829957195, duration: 13968, easing: linear}, function(){animate('up-and-down')}
      );
      break;
    case 'fly-in-out':
      view.animate({center: [21.215044649605375,81.40073619748924], zoom: 5.4217680042393335, duration: 10435, },
          {center: [0,0], zoom: 1, duration: 10000, },
          {center: [47.1152776900088,42.09593568034825], zoom: 5.8619280948873635, duration: 11723, },
          {center: [0,0], zoom: 1, duration: 10000, },
          {center: [-8.169763779945583,68.62592237625105], zoom: 6.320601473531825, duration: 12365, },
          {center: [0,0], zoom: .5, duration: 6937, },
          {center: [0,0], zoom: .5, duration: 6937, },
          {center: [17.349720488526433,-71.8743853752681], zoom: 5.755261428220694, duration: 11389, },
          {center: [0,0], zoom: .5, duration: 6937, },
          {center: [-9.725722499938772,2.6583887203334626], zoom: 6.101928094887363, duration: 12726, },
          {center: [0,0], zoom: .5, duration: 6937, },
          {center: [43.61520040214384,74.78550412339698], zoom: 6.975261428220699, duration: 13397, },
          {center: [0,0], zoom: .5, duration: 6937, }, function(){animate('fly-in-out')}
      );
      break;
  }
}
  // animate(state.mode)

view.addChangeListener("center", function(){
  //console.log("new center", view.getCenter(), view.getZoom())
})

let animationStrArr = [];
document["onkeypress"] = function (e) {
  switch(e.key){
    case '1':
      view.cancelAnimations()
      animate('up-and-down');
          break;
    case '2':
      view.cancelAnimations()
      animate('fly-in-out');
      break;
    case '0':
      animationStrArr.push("{center: [0,0], zoom: 1, duration: 10000, },\n");
      break;
    case 'a':
      animationStrArr.push(getCurrentCoords(view));
      break;
    case 'c':
      console.log(getCurrentCoords(view))
      break;
    case 'l':
      let str = 'view.animate(';
      for (let i = 0; i < animationStrArr.length; i++) {
        str+=animationStrArr[i];
      }
      str+=');';
      console.log(str);
      break;
    case ' ':
      view.cancelAnimations();
      break;
  }
};

function getCurrentCoords(view){
  const center = view.getCenter();
  return `{center: [${center}], zoom: ${view.getZoom()}, duration: ${Math.floor(view.getZoom()*2000)}, easing: linear},\n`;
}
